<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'AuthLayout',
  components: {
    
  },
  data() {
    return {
     
    }
  },
  created() {
    
  },
  methods: {
    
  },
}
</script>
